exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-costs-js": () => import("./../../../src/pages/costs.js" /* webpackChunkName: "component---src-pages-costs-js" */),
  "component---src-pages-design-process-js": () => import("./../../../src/pages/design-process.js" /* webpackChunkName: "component---src-pages-design-process-js" */),
  "component---src-pages-garden-design-in-tavistock-js": () => import("./../../../src/pages/garden-design-in-tavistock.js" /* webpackChunkName: "component---src-pages-garden-design-in-tavistock-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landscape-gardener-plymouth-js": () => import("./../../../src/pages/landscape-gardener-plymouth.js" /* webpackChunkName: "component---src-pages-landscape-gardener-plymouth-js" */),
  "component---src-templates-gallery-large-template-js": () => import("./../../../src/templates/galleryLargeTemplate.js" /* webpackChunkName: "component---src-templates-gallery-large-template-js" */),
  "component---src-templates-gallery-small-template-js": () => import("./../../../src/templates/gallerySmallTemplate.js" /* webpackChunkName: "component---src-templates-gallery-small-template-js" */)
}

